import React from 'react';
import './Careers.css';
import car from '../assets/images/carerr.jpeg'

// Job Listings Data
const jobListings = [
  { 
    title: 'Project Manager', 
    description: 'Lead construction projects from start to finish.'
  },
  { 
    title: 'Site Engineer', 
    description: 'Manage and supervise on-site construction activities.'
  },
  { 
    title: 'Construction Foreman', 
    description: 'Oversee daily operations on construction sites.'
  },
  { 
    title: 'Architect', 
    description: 'Design and plan construction projects.'
  },
  { 
    title: 'Estimator', 
    description: 'Calculate project costs and prepare estimates.'
  },
  { 
    title: 'Safety Officer', 
    description: 'Ensure safety protocols are followed on site.'
  }
  // Add more job listings as needed
];

const Careers = () => {
  return (
    <div className="careers-wrapper">
      <section className="intro-section">
        <img className="intro-image" src={car} alt='Join Our Team' />
        <h1 className="title">Join Our Team at SR Constructions</h1>
        <p className="paragraph">
          At SR Constructions, we are dedicated to building not just structures but a thriving team. For over 20 years, we have been the preferred partner for ambitious construction projects. We are committed to quality, innovation, and supporting our team members.
        </p>
        <p className="paragraph">
          If you are passionate about construction and want to be part of a company that values integrity, teamwork, and growth, we invite you to explore our career opportunities and join us in building a legacy of excellence.
        </p>
      </section>

      <section className="join-section">
        <h2>Career Opportunities</h2>
        <div className="job-list">
          {jobListings.map((job, index) => (
            <div className="job-item" key={index}>
              <div className="job-details">
                <h2 className="job-title">{job.title}</h2>
                <p className="job-description">{job.description}</p>
                <a className="apply-button" href={`mailto:info@srconstructions07.in?subject=Application for ${job.title}`} target="_blank" rel="noopener noreferrer">
                  Enquire Now
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="submit-section">
        <h2>Ready to Build Your Future with Us?</h2>
      
        <a className="apply-button" href={`mailto:info@srconstructions07.in`} target="_blank" rel="noopener noreferrer">
        <p>Submit your resume and cover letter to info@srconstructions07.in</p>
                </a>
      </section>
    </div>
  );
};

export default Careers;
