import React, { useState } from 'react';
import './ContactUs.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    if (!formData.phone) errors.phone = 'Phone number is required';
    if (!formData.message) errors.message = 'Message is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Using Web3Forms API to submit form data
    const form = new FormData(e.target);
    form.append('access_key', 'abd21a73-86d9-4496-b5c9-a0da70a59ba4');

    const formObject = Object.fromEntries(form);
    const json = JSON.stringify(formObject);

    try {
      const res = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: json,
      }).then((res) => res.json());

      if (res.success) {
        setSuccessMessage('Your message has been sent successfully!');
        setFormData({ name: '', email: '', phone: '', message: '' });
        setErrors({});
      } else {
        setErrors({ submit: res.message || 'Failed to send the message, please try again later.' });
      }
    } catch (error) {
      setErrors({ submit: 'Failed to send the message, please try again later.' });
    }
  };

  return (
    <div className="contactUs">
      <div className="contactContainer">
        <div className="contactInfo">
          <h2>Contact Information</h2>
          <div className="contactItem">
            <span className="icon"><FaMapMarkerAlt /></span>
            <p><strong>Address:</strong> Plot No.116w/p, Venkateswara colony, road no-2,Hasthinapuram,Rangareddy Dist, Hyderabad.
            </p>
          </div>
          <div className="contactItem">
            <span className="icon"><FaEnvelope /></span>
            <p><strong>Email:</strong> info@srconstructions07.com</p>
          </div>
          <div className="contactItem">
            <span className="icon"><FaPhoneAlt /></span>
            <p><strong>Phone Number:</strong> +91 6302905016</p>
          </div>
        </div>
        <div className="contactForm">
          <h2>Send Us a Message</h2>
          <form onSubmit={handleSubmit}>
            <div className="formGroup">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <p className="errorMessage">{errors.name}</p>}
            </div>
            <div className="formGroup">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <p className="errorMessage">{errors.email}</p>}
            </div>
            <div className="formGroup">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={errors.phone ? 'error' : ''}
              />
              {errors.phone && <p className="errorMessage">{errors.phone}</p>}
            </div>
            <div className="formGroup">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className={errors.message ? 'error' : ''}
              ></textarea>
              {errors.message && <p className="errorMessage">{errors.message}</p>}
            </div>
            {errors.submit && <p className="errorMessage">{errors.submit}</p>}
            {successMessage && <p className="successMessage">{successMessage}</p>}
            <button type="submit" className="btnPrimary">Submit</button>
          </form>
        </div>
      </div>
      <div className="mapContainer">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243675.74997870266!2d78.35806967812503!3d17.3909672778625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba34d99c61649%3A0xaf01a79c6b286ad8!2sSr%20-%20Construction%20Contractors%20In%20Hyderabad%20Independent%20House%20Top%20Builders%20Low%20Cost%20Best%20Apartment%20Planning%20Elevations%20Hyd!5e0!3m2!1sen!2sin!4v1724222683335!5m2!1sen!2sin"
          className="map"
          allowFullScreen=""
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
