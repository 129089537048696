import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PropertyList.css'; // Import CSS for styling

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://julmetech.com/properties')
      .then(response => {
        setProperties(response.data);
      })
      .catch(error => {
        console.error('Error fetching properties:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this property?')) {
      axios.delete(`https://julmetech.com/properties/${id}`)
        .then(() => {
          setProperties(properties.filter(property => property.id !== id));
        })
        .catch(error => {
          console.error('Error deleting property:', error);
        });
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="property-list-container">
      <h2>Property List</h2>
      <div className="property-list">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Location</th>
              <th>Square Feet</th>
              <th>Image</th>
              <th>Actions</th> {/* New column for actions */}
            </tr>
          </thead>
          <tbody>
            {properties.map(property => (
              <tr key={property.id} className="animated-row">
                <td>{property.name}</td>
                <td>{property.price}</td>
                <td>{property.location}</td>
                <td>{property.sqft}</td>
                <td><img src={`https://julmetech.com${property.image}`} alt={property.name} width="100" /></td>
                <td>
                  <button onClick={() => handleDelete(property.id)} className="delete-button">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PropertyList;
