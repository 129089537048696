import React, { useState } from 'react';
import axios from 'axios';
import './AddPropertyForm.css'; // Import CSS for styling

const AddPropertyForm = () => {
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [imagePreview, setImagePreview] = useState('');

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.get('name')) errors.name = 'Name is required';
    if (!formData.get('price')) errors.price = 'Price is required';
    if (!formData.get('location')) errors.location = 'Location is required';
    if (!formData.get('sqft')) errors.sqft = 'Square Feet is required';
    if (!formData.get('image')) errors.image = 'Image is required';
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors({});
    setResponseMessage('');
    setLoading(true);

    const formData = new FormData(event.target);
    const errors = validateForm(formData);

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    axios.post('https://julmetech.com/properties', formData)
      .then(response => {
        setResponseMessage('Property added successfully!');
        event.target.reset();
        setImagePreview('');
      })
      .catch(error => {
        setResponseMessage('Error adding property. Please try again.');
        console.error('Error adding property:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      const file = URL.createObjectURL(event.target.files[0]);
      setImagePreview(file);
    }
  };

  return (
    <div className="add-property-form">
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Property Name" />
        {formErrors.name && <p className="error">{formErrors.name}</p>}
        <input type="text" name="price" placeholder="Price" />
        {formErrors.price && <p className="error">{formErrors.price}</p>}
        <input type="text" name="location" placeholder="Location" />
        {formErrors.location && <p className="error">{formErrors.location}</p>}
        <input type="number" name="sqft" placeholder="Square Feet" />
        {formErrors.sqft && <p className="error">{formErrors.sqft}</p>}
        <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
        {formErrors.image && <p className="error">{formErrors.image}</p>}
        {imagePreview && <img src={imagePreview} alt="Image Preview" className="image-preview" />}
        <button type="submit" disabled={loading}>
          {loading ? 'Adding...' : 'Add Property'}
        </button>
      </form>
      {responseMessage && <p className="response-message">{responseMessage}</p>}
    </div>
  );
};

export default AddPropertyForm;
