import React, { useState, useEffect } from 'react';
import './loade.css'; // Make sure to import the CSS file
import load from './logo.jpg'

const LoadingSpinner = () => {
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 1;
          } else {
            clearInterval(interval);
            return 100;
          }
        });
      }, 30); // Adjust the timing to make the progress bar complete smoothly
  
      return () => clearInterval(interval);
    }, []);
  
    return (
      <div className="loading-container">
        <img src={load} alt="Company Logo" className="loading-logo" />
        <h1 className="loading-company-name">SR CONSTRUCTIONS</h1>
        <div className="loading-bar">
          <div className="loading-progress" style={{ width: `${progress}%` }}></div>
        </div>
        <p className="loading-percentage">{progress}%</p>
      </div>
    );
  };
  

export default LoadingSpinner;
