import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProjectList.css'; // Import CSS for styling

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://julmetech.com/projects')
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      axios.delete(`https://julmetech.com/projects/${id}`)
        .then(() => {
          setProjects(projects.filter(project => project.id !== id));
        })
        .catch(error => {
          console.error('Error deleting project:', error);
        });
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="project-list-container">
      <h2>Project List</h2>
      <div className="project-list">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Description</th>
              <th>Image</th>
              <th>Actions</th> {/* New column for actions */}
            </tr>
          </thead>
          <tbody>
            {projects.map(project => (
              <tr key={project.id} className="animated-row">
                <td>{project.name}</td>
                <td>{project.location}</td>
                <td>{project.description}</td>
                <td><img src={`https://julmetech.com${project.image}`} alt={project.name} width="100" /></td>
                <td>
                  <button onClick={() => handleDelete(project.id)} className="delete-button">Delete</button>
                </td>  
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectList;
