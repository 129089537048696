import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';

import './Home.css';
import Carousel3D from './animations/Carousel3D';
import img1 from '../pages/animations/carosl/cc1.jpg'
import img2 from '../pages/animations/carosl/cc2.jpg';
import img3 from '../pages/animations/carosl/cc3.jpg';
import img4 from '../pages/animations/carosl/cc4.jpg';
import img5 from '../pages/animations/carosl/cc5.jpg';
import logoimg from '../assets/logo-removebg-preview.png';
import { Link } from 'react-router-dom';


const CoreValues = [
  {
    title: "Integrity",
    description: "We maintain the highest standards of integrity in all our actions.",
    image: require('../assets/images/integrity.jpg'), // Example path
  },
  {
    title: "Quality",
    description: "We deliver quality work and excellent services.",
    image: require('../assets/images/quality.jpeg'), // Example path
  },
  {
    title: "Innovation",
    description: "We foster innovation to stay ahead in the industry.",
    image: require('../assets/images/innovation.jpeg'), // Example path
  },
  // Add more core values as needed
  {title: 'Loyalty',
     description: 'Building long-term relationships with clients and stakeholders based on mutual respect and dedication.' 
, image: require('../assets/images/loyality.webp'),
  },
];

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
];

const testimonials = [
  {
    name: "kunchakuri prashanth",
   
    text: "Nice design and quality of the building is excellent. Completed on time with perfect design. I have suggested for extra masonry work. S R construction Srinivas Reddy garu very supportive person he built that with good quality. Each and every part of the building is build with good material including the taps, pipes, granet, gates, painting, electrical wiring material, Elevation design tiles and glass  etc… Thank you srinivas garu",
   
  },
  {
    name: "Srinivas arroju",
    
    text: "I am So Happy with Support, Service and Ontime  Completion of my Site at Narapally with said Material and also Happy with execution as per the Engineer Plan. And also so supportive in improvising design, tq Srinivas garu and All the Best for your future Projects",
   
  },
  {
    name: "Tirumala Reddy Kopparthi",
    
    text: "Mr Srinivas provides quality services i am 100% happy for choosing him.Give ur construction works to him and you will definitely won't regret.Positive->Responsiveness, Punctuality, Quality, Professionalism, Value",
   
  },
  {
    name: "Muhammad Altaf",
    
    text: "The planning isdone by the sr construction is doen very nicely the desi g are very nice the construction 🚧 is deon in low cost with high quality materials …",
   
  },
  {
    name: "saiprasad Punreddy",
    
    text: "I must say the best construction is done by sr constructions within my budget and in time… good to do bussiness with this company thank you,Positive->Responsiveness, Punctuality, Quality, Professionalism, Value,Services->Home renovations, New home construction, Lending services, Remodeling, Plumbing fixture installation, Fan repair",
   
  },
  // Add more testimonials as needed
];



const Home = () => {
  const [showContact, setShowContact] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handleContactClick = () => {
    setShowContact(!showContact);
  };

  return (
    <div className="home-page">
      <Carousel3D images={images} logo={logoimg} />
      {/* Hero Section */}
      <div className="hero-container">
        <div className="hero-content">
          <h1>Building Legacies for Over 20 Years</h1>
          <p>Transforming your vision into reality with our commitment to excellence.</p>
          <Link to="/contact" className="hero-btn">Get Started </Link>
        </div>
      </div>
      
    

      
      {/* About Us Section */}
      <div className="about-us">
        <h2>Why SR Constructions?</h2>
        <p>
          At SR Constructions, our success is driven by our unwavering commitment to the client's vision. Our approach combines a culture of balance, integrity, leadership, and loyalty. We believe in creating an environment that fosters collaboration and engagement, ensuring that every project meets the highest standards of safety, quality, practicality, and beauty.
        </p>
        <p>
          Our goal is not just to build structures but to create assets that stand the test of time. With over two decades of experience, we have completed more than 100 projects and served over 150 clients, building relationships that last as long as the buildings we construct.
        </p>
        <div className="stats">
      <div className="stat">
        <h3>
          <CountUp end={100} duration={5} suffix="+" />
        </h3>
        <p>Projects Completed</p>
      </div>
      <div className="stat">
        <h3>
          <CountUp end={20} duration={5} suffix=" Years" />
        </h3>
        <p>Experience in the Field</p>
      </div>
      <div className="stat">
        <h3>
          <CountUp end={150} duration={5} suffix="+" />
        </h3>
        <p>Clients Served</p>
      </div>
    </div>
      </div>

      {/* Core Values Section */}
    <div className="core-values">
  <h2>Our Core Values</h2>
  <div className="values-container">
    {CoreValues.map((value, index) => (
      <div key={index} className="value-card">
        <div className="image-container">
          <img src={value.image} alt={value.title} />
        </div>
        <h3>{value.title}</h3>
        <p>{value.description}</p>
      </div>
    ))}
  </div>
</div>
      
      {/* Services Section */}
      {/* <div className="services">
        <h2>Our Services</h2>
        <p>We offer a range of construction services tailored to meet diverse needs and ensure exceptional results.</p>
        <div className="services-container">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
       */}
      {/* Projects Section */}
      {/* <div className="projects">
        <h2>Our Projects</h2>
        <p>Explore some of our most notable projects, showcasing our expertise and commitment to excellence.</p>
        <div className="projects-gallery">
          {projects.map((project, index) => (
            <div key={index} className="project-card">
              <img src={project.image} alt={project.title} />
              <div className="project-info">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
       */}
      {/* Client Collaboration Section */}
      <div className="client-collaboration">
      <h2>Collaborating for Success</h2>
      <p>
        Our collaborative approach involves working closely with a diverse range of stakeholders to achieve project success.
        From initial consultations to final delivery, we ensure that every aspect of the project is handled with expertise and care.
      </p>
      <div className="collaboration-flow">
        <div className="flow-step">
          <div className="step-icon">1</div>
          <h3>Step 1</h3>
          <p>Initial Consultation and Planning</p>
        </div>
        <div className="flow-step">
          <div className="step-icon">2</div>
          <h3>Step 2</h3>
          <p>Design and Development</p>
        </div>
        <div className="flow-step">
          <div className="step-icon">3</div>
          <h3>Step 3</h3>
          <p>Construction and Execution</p>
        </div>
        <div className="flow-step">
          <div className="step-icon">4</div>
          <h3>Step 4</h3>
          <p>Final Delivery and Review</p>
        </div>
      </div>
    </div>
    <div className="testimonials-slider1"> <h1>What Our Clients Say</h1></div>
   
    <div className="testimonials-slider">
     
      <div className="slider-container">
        <button className="arrow left-arrow" onClick={handlePrevious}>
          &#10094;
        </button>
        <div className="testimonial-slide">
          
          <div className="testimonial-content">
            <p className="testimonial-text">"{testimonials[currentIndex].text}"</p>
            <h3 className="testimonial-name">{testimonials[currentIndex].name}</h3>
            <p className="testimonial-position">{testimonials[currentIndex].position}</p>
          </div>
        </div>
        <button className="arrow right-arrow" onClick={handleNext}>
          &#10095;
        </button>
      </div>
      <div className="dots-container">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
      
      {/* Contact Us Section */}
      {/* <div className={`contact-us ${showContact ? 'show' : ''}`} id="contact">
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Whether you have a question, want to discuss a project, or just want to say hello, feel free to reach out.</p>
        <form className="contact-form">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" placeholder="Your Message" required></textarea>
          <button type="submit">Send Message</button>
        </form>
        <button className="contact-toggle" onClick={handleContactClick}>
          {showContact ? 'Hide Contact Form' : 'Show Contact Form'}
         
        </button>
      </div>
       */}
      
    </div>
  );
};

export default Home;




