// PortfolioCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './PortfolioCard.css';

const PortfolioCard = ({ property }) => {
  return (
    <div className="portfolio-card">
  <div className="portfolio-card-image">  
    <img src={`https://julmetech.com${property.image}`} alt={property.name} />
  </div>
  <div className="portfolio-card-info">
    <h2 className="portfolio-card-title">{property.name}</h2>
    <p className="portfolio-card-price">Price: ₹{property.price}</p>
    <p className="portfolio-card-location">Location: {property.location}</p>
    <p className="portfolio-card-sqft">Square Feet: {property.sqft}</p>
    <Link className="contact-us-btn" to="/contact">
      GET A QUOTE
    </Link>
   
  </div>
</div>

  );
};

export default PortfolioCard;
