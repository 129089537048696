import React, { useState, useEffect } from 'react';
import './ProjectsPage.css';

const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    const imageBaseUrl = 'https://julmetech.com'; // Ensure this is correct

    useEffect(() => {
        fetch('https://julmetech.com/projects')
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(error => console.error('Error fetching projects:', error));
    }, []);

    const handleCardClick = (project) => {
        setSelectedProject(project);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
    };

    return (
        <div className="projects-page">
            <h1>Our Projects</h1>
            <div className="projects-grid">
                {projects.map((project) => (
                    <div key={project.id} className="project-card" onClick={() => handleCardClick(project)}>
                        <img src={`${imageBaseUrl}${project.image}`} alt={project.name} />
                        <h2>{project.name}</h2>
                        <p>Location: {project.location}</p>
                        <p>Description: {project.description}</p>
                    </div>
                ))}
            </div>
            {selectedProject && (
                <div className="modal-backdrop" onClick={handleCloseModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>{selectedProject.name}</h2>
                        <p>Location: {selectedProject.location}</p>
                        <p>Description: {selectedProject.description}</p>
                        
                      
                        <img src={`${imageBaseUrl}${selectedProject.image}`} alt={selectedProject.name} />
                        <button onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectsPage;
